@import url('https://fonts.googleapis.com/css2?family=Dynalight&family=Playball&family=Roboto:wght@300;400&display=swap');
body {
  margin: 0px;
  font-family: 'Dynalight', cursive;
  font-family: 'Playball', cursive;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0E0D9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
