.container{
    display: flex;
    justify-content: space-between;
    padding: 101px 110px;
justify-content:center;

}
.card{
   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   font-size:11px ;
}

.aboutimg{
    width: 622px;
    
}
.aboutimg img{
    height: 494px;
    border-radius: 4px;
}
.content{
    width: 518px;
    height: 296px;
    color: #323A30;
    
}
.content h3{
    font-family: Times New Roman;
    font-weight: 700;
    font-size: 48px;
    display: inline;
}
.content p{
    font-size: 22px;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
}
.exp{
    padding-left: 20px;
    font-weight: normal;
    font-size: 36px;
    font-family: 'Playball', cursive;
    color: gray;
}
.popwrap{
margin: 150px;
color: #323A30;

}
.popwrap h3{
    font-family: Times New Roman;
    font-weight: 700;
    font-size: 48px;
    display: inline;
}
.popimg{
   margin: 50px;
}
.btn{
    width: 100%;
}
h4{
    color: #323A30;
text-align: center;
}
@media (max-width:968px){
.container{
    display: block;
    padding: 0px;
    margin-top: 100px;
    margin-bottom: 100px;
    overflow-x: hidden;
}
 .aboutimg{
    width: 100%;
    right: 0px;
   
 }
 .aboutimg img{
    width: 100%;
    right: 0px;

 }
 .exp{
    font-size: 28px;
 }
 .content{
    width: 100%;
    display: block;
    height: 100%;
    margin-left: 0px;
 }
 .content h3{
    font-size: 28px;
    padding: 10px;
 }
 .content p{
   padding: 10px;
    font-size: 16px;
 }
 .popwrap{
    margin: 0px auto;
    padding: 10px;
 }
 .popwrap h3{
    font-size: 28px;
    margin-top: 50px;
 }
 .popimg{
    margin: 0 auto;
    
 }
 .popimg img{
    display: block;
    width: 100%;
 }
 .description{
    margin: 0 auto;
 }
 .btn{
    margin-bottom: 40px;
 }
 .card{font-size: 11.5px;}
}